import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/assets/dsfr_plus_icons.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/dsfr/artwork/dark.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/dsfr/artwork/light.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/dsfr/artwork/system.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/apple-touch-icon.png");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.ico");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/@codegouvfr/react-dsfr/next-appdir/DsfrProvider.js");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/app/layout.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/app/Matomo.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/app/MuiDsfrThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/app/SentryClientProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/app/StartDsfr.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/etabli/etabli/src/components/live-chat/LiveChatProvider.tsx")